<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="600"
      @click:outside="reset"
    >
      <v-card>
        <v-card-title class="headline">
          {{ isEditing ? "Edit" : "Add a new" }} Image
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5 grey lighten-4">
          <v-form @submit.prevent="save" method="post" id="pdf-image-form">
            <vue-select-image
              :dataImages="imagesProperty"
              @onselectimage="onSelectImage"
              w="150"
            >
            </vue-select-image>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="reset()">Close</v-btn>
          <v-btn
            color="accent"
            text
            type="submit"
            :loading="loading"
            form="pdf-image-form"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteImage.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Remove Selected Image</v-card-title>
        <v-card-text>Are you sure you wish to remove</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteImage.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueSelectImage from "vue-select-image";
import "vue-select-image/dist/vue-select-image.css";

export default {
  props: {
    property: {
      type: Object,
      default: null,
    },
  },

  components: {
    VueSelectImage,
  },

  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      selectedImage: {},
      selectedImages: [],
      selectedImages3: [],
      deleteImage: {
        dialog: false,
        image: {},
        page: null,
        loading: false,
      },

      fields: {
        selectedImage: {},
        page: null,
        order: null,
      },
      errors: {},
    };
  },

  computed: {
    initialSelected3() {
      let images = this.property.pdf_images3;

      images.map((item) => {
        item.id = item.image.id;
        item.src = item.image.asset_urls.url;
        item.alt = item.image.name;
      });

      return images;
    },

    initialSelected() {
      let images = this.property.pdf_images;

      images.map((item) => {
        item.id = item.image.id;
        item.src = item.image.asset_urls.url;
        item.alt = item.image.name;
      });

      return images;
    },

    imagesProperty() {
      let images = this.property.images;

      images.map((item) => {
        item.src = item.asset_urls.url;
        item.alt = item.name;
      });

      return images;
    },
  },

  methods: {
    openDelete(image) {
      this.deleteImage.dialog = true;
      this.deleteImage.image = image.id;
    },

    resetDelete() {
      this.deleteImage.dialog = false;
      this.deleteImage.page = null;
      this.deleteImage.image = null;
      this.deleteImage.loading = false;
    },

    saveDelete() {
      this.deleteImage.loading = true;

      let payload = {
        appId: this.$route.params.id,
        propertyId: this.$route.params.propertyId,
        imageId: this.deleteImage.image,
      };

      this.$store
        .dispatch("melrose/propertiesStore/deleteImage", payload)
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteImage.loading = false;
        });
    },

    open(page = null, order = null) {
      this.fields.page = page;
      this.fields.order = order;

      this.dialog = true;
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.loading3 = false;
      this.isEditing = false;
      this.selectedImages = [];
      this.selectedImages3 = [];
      this.fields = {
        selectedImages: [],
      };
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      payload.propertyId = this.$route.params.propertyId;
      payload.fields.selectedImage = this.selectedImage;

      this.$store
        .dispatch("melrose/propertiesStore/savePdfImages", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    save3() {
      this.loading3 = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      payload.propertyId = this.$route.params.propertyId;
      payload.fields.selectedImage = this.selectedImage;

      this.$store
        .dispatch("melrose/propertiesStore/savePdfImages", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading3 = false;
        });
    },

    onSelectImage(img) {
      this.selectedImage = img;
    },
    onSelectMultipleImage(images) {
      this.selectedImages = images;
    },

    onSelectImage3() {},
    onSelectMultipleImage3(images) {
      this.selectedImages3 = images;
    },
  },
};
</script>
