<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="auto"> </v-col>
      </v-row>
      <v-row align="start">
        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title class="grey lighten-4">
              <div class="flex-grow-1">Pdf</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.pdfDialog.open(property)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-simple-table>
                <tbody>
                  <tr>
                    <th>Services</th>
                    <td>{{ property.services }}</td>
                  </tr>
                  <tr>
                    <th>Description</th>
                    <td>{{ property.pdf_description }}</td>
                  </tr>
                  <tr>
                    <th>Show Gallery Page</th>
                    <td>{{ property.show_gallery ? "Yes" : "No" }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title class="grey lighten-4">
              <div class="flex-grow-1">Page 2</div>
            </v-card-title>

            <v-simple-table>
              <tbody>
                <tr>
                  <th>Image 1</th>
                  <td>
                    <v-btn
                      depressed
                      color="green lighten-4 green--text"
                      class="mr-2"
                      @click="$refs.pdfImageDialog.open(2, 0)"
                    >
                      Select/Change Image
                    </v-btn>
                    <v-btn
                      v-if="initialSelected[0]"
                      depressed
                      color="red lighten-4 red--text"
                      @click="
                        $refs.pdfImageDialog.openDelete(initialSelected[0])
                      "
                    >
                      Remove
                    </v-btn>
                  </td>
                  <td>
                    <v-img
                      v-if="initialSelected[0]"
                      :src="initialSelected[0].image.asset_urls.url"
                      height="100px"
                      width="100px"
                    ></v-img>
                  </td>
                </tr>

                <tr>
                  <th>Image 2</th>
                  <td>
                    <v-btn
                      depressed
                      color="green lighten-4 green--text"
                      class="mr-2"
                      @click="$refs.pdfImageDialog.open(2, 1)"
                    >
                      Select/Change Image
                    </v-btn>
                    <v-btn
                      v-if="initialSelected[1]"
                      depressed
                      color="red lighten-4 red--text"
                      @click="
                        $refs.pdfImageDialog.openDelete(initialSelected[1])
                      "
                    >
                      Remove
                    </v-btn>
                  </td>
                  <td>
                    <v-img
                      v-if="initialSelected[1]"
                      :src="initialSelected[1].image.asset_urls.url"
                      height="100px"
                      width="100px"
                    ></v-img>
                  </td>
                </tr>
                <tr>
                  <th>Image 3</th>
                  <td>
                    <v-btn
                      depressed
                      color="green lighten-4 green--text"
                      class="mr-2"
                      @click="$refs.pdfImageDialog.open(2, 2)"
                    >
                      Select/Change Image
                    </v-btn>
                    <v-btn
                      v-if="initialSelected[2]"
                      depressed
                      color="red lighten-4 red--text"
                      @click="
                        $refs.pdfImageDialog.openDelete(initialSelected[2])
                      "
                    >
                      Remove
                    </v-btn>
                  </td>
                  <td>
                    <v-img
                      v-if="initialSelected[2]"
                      :src="initialSelected[2].image.asset_urls.url"
                      height="100px"
                      width="100px"
                    ></v-img>
                  </td>
                </tr>
                <tr>
                  <th>Image 4</th>
                  <td>
                    <v-btn
                      depressed
                      color="green lighten-4 green--text"
                      class="mr-2"
                      @click="$refs.pdfImageDialog.open(2, 3)"
                    >
                      Select/Change Image
                    </v-btn>
                    <v-btn
                      v-if="initialSelected[3]"
                      depressed
                      color="red lighten-4 red--text"
                      @click="
                        $refs.pdfImageDialog.openDelete(initialSelected[3])
                      "
                    >
                      Remove
                    </v-btn>
                  </td>
                  <td>
                    <v-img
                      v-if="initialSelected[3]"
                      :src="initialSelected[3].image.asset_urls.url"
                      height="100px"
                      width="100px"
                    ></v-img>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card outlined class="mb-6">
            <v-card-title class="grey lighten-4">
              <div class="flex-grow-1">Page 3</div>
            </v-card-title>

            <v-simple-table>
              <tbody>
                <tr>
                  <th>Image 1</th>
                  <td>
                    <v-btn
                      depressed
                      color="green lighten-4 green--text"
                      class="mr-2"
                      @click="$refs.pdfImageDialog.open(3, 0)"
                    >
                      Select/Change Image
                    </v-btn>
                    <v-btn
                      v-if="initialSelected3[0]"
                      depressed
                      color="red lighten-4 red--text"
                      @click="
                        $refs.pdfImageDialog.openDelete(initialSelected3[0])
                      "
                    >
                      Remove
                    </v-btn>
                  </td>
                  <td>
                    <v-img
                      v-if="initialSelected3[0]"
                      :src="initialSelected3[0].image.asset_urls.url"
                      height="100px"
                      width="100px"
                    ></v-img>
                  </td>
                </tr>

                <tr>
                  <th>Image 2</th>
                  <td>
                    <v-btn
                      depressed
                      color="green lighten-4 green--text"
                      class="mr-2"
                      @click="$refs.pdfImageDialog.open(3, 1)"
                    >
                      Select/Change Image
                    </v-btn>
                    <v-btn
                      v-if="initialSelected3[1]"
                      depressed
                      color="red lighten-4 red--text"
                      @click="
                        $refs.pdfImageDialog.openDelete(initialSelected3[1])
                      "
                    >
                      Remove
                    </v-btn>
                  </td>
                  <td>
                    <v-img
                      v-if="initialSelected3[1]"
                      :src="initialSelected3[1].image.asset_urls.url"
                      height="100px"
                      width="100px"
                    ></v-img>
                  </td>
                </tr>
                <tr>
                  <th>Image 3</th>
                  <td>
                    <v-btn
                      depressed
                      color="green lighten-4 green--text"
                      class="mr-2"
                      @click="$refs.pdfImageDialog.open(3, 2)"
                    >
                      Select/Change Image
                    </v-btn>
                    <v-btn
                      v-if="initialSelected3[2]"
                      depressed
                      color="red lighten-4 red--text"
                      @click="
                        $refs.pdfImageDialog.openDelete(initialSelected3[2])
                      "
                    >
                      Remove
                    </v-btn>
                  </td>
                  <td>
                    <v-img
                      v-if="initialSelected3[2]"
                      :src="initialSelected3[2].image.asset_urls.url"
                      height="100px"
                      width="100px"
                    ></v-img>
                  </td>
                </tr>
                <tr>
                  <th>Image 4</th>
                  <td>
                    <v-btn
                      depressed
                      color="green lighten-4 green--text"
                      class="mr-2"
                      @click="$refs.pdfImageDialog.open(3, 3)"
                    >
                      Select/Change Image
                    </v-btn>
                    <v-btn
                      v-if="initialSelected3[3]"
                      depressed
                      color="red lighten-4 red--text"
                      @click="
                        $refs.pdfImageDialog.openDelete(initialSelected3[3])
                      "
                    >
                      Remove
                    </v-btn>
                  </td>
                  <td>
                    <v-img
                      v-if="initialSelected3[3]"
                      :src="initialSelected3[3].image.asset_urls.url"
                      height="100px"
                      width="100px"
                    ></v-img>
                  </td>
                </tr>
                <tr>
                  <th>Image 5</th>
                  <td>
                    <v-btn
                      depressed
                      color="green lighten-4 green--text"
                      class="mr-2"
                      @click="$refs.pdfImageDialog.open(3, 4)"
                    >
                      Select/Change Image
                    </v-btn>
                    <v-btn
                      v-if="initialSelected3[4]"
                      depressed
                      color="red lighten-4 red--text"
                      @click="
                        $refs.pdfImageDialog.openDelete(initialSelected3[4])
                      "
                    >
                      Remove
                    </v-btn>
                  </td>
                  <td>
                    <v-img
                      v-if="initialSelected3[4]"
                      :src="initialSelected3[4].image.asset_urls.url"
                      height="100px"
                      width="100px"
                    ></v-img>
                  </td>
                </tr>
                <tr>
                  <th>Image 6</th>
                  <td>
                    <v-btn
                      depressed
                      color="green lighten-4 green--text"
                      class="mr-2"
                      @click="$refs.pdfImageDialog.open(3, 5)"
                    >
                      Select/Change Image
                    </v-btn>
                    <v-btn
                      v-if="initialSelected3[5]"
                      depressed
                      color="red lighten-4 red--text"
                      @click="
                        $refs.pdfImageDialog.openDelete(initialSelected3[5])
                      "
                    >
                      Remove
                    </v-btn>
                  </td>
                  <td>
                    <v-img
                      v-if="initialSelected3[5]"
                      :src="initialSelected3[5].image.asset_urls.url"
                      height="100px"
                      width="100px"
                    ></v-img>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <pdf-dialog ref="pdfDialog" />
    <pdf-image-dialog ref="pdfImageDialog" :property="property" />
  </div>
</template>

<script>
import PdfDialog from "./components/PdfDialog.vue";
import PdfImageDialog from "./components/pdf/PdfImageDialog.vue";

export default {
  components: {
    PdfDialog,
    PdfImageDialog,
  },

  data() {
    return {
      selectedImages: [],
      selectedImages3: [],

      emptyArray: [],

      fields: {
        selectedImages: [],
      },
      dialog: false,
      loading: false,
      loading3: false,
      isEditing: false,
      images: {},
      errors: {},
    };
  },

  computed: {
    property() {
      return this.$store.getters["melrose/propertiesStore/get"];
    },

    initialSelected3() {
      return this.property.pdf_images3;
    },

    initialSelected() {
      return this.property.pdf_images;
    },

    imagesProperty() {
      let images = this.property.images;

      images.map((item) => {
        item.src = item.asset_urls.url;
        item.alt = item.name;
      });

      return images;
    },
  },
  methods: {
    reset() {
      this.dialog = false;
      this.loading = false;
      this.loading3 = false;
      this.isEditing = false;
      this.selectedImages = [];
      this.selectedImages3 = [];
      this.fields = {
        selectedImages: [],
      };
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      payload.propertyId = this.$route.params.propertyId;
      payload.fields.selectedImages = this.selectedImages;

      this.$store
        .dispatch("melrose/propertiesStore/savePdfImages", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    save3() {
      this.loading3 = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      payload.propertyId = this.$route.params.propertyId;
      payload.fields.selectedImages3 = this.selectedImages3;

      this.$store
        .dispatch("melrose/propertiesStore/savePdfImages", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading3 = false;
        });
    },

    onSelectImage() {},
    onSelectMultipleImage(images) {
      this.selectedImages = images;
    },

    onSelectImage3() {},
    onSelectMultipleImage3(images) {
      this.selectedImages3 = images;
    },
  },
};
</script>
